import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Pop!`}{`_`}{`OS och Fedora i nya upplagor. SteamVR dissar macOS. Xiaomi i blåsväder, och gäng ett Trevliga tips med Raymond Knops som fanbärare.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Pop!_OS 20.04: `}<a parentName="li" {...{
          "href": "https://blog.system76.com/post/616861064165031936/whats-new-with-popos-2004-lts"
        }}>{`https://blog.system76.com/post/616861064165031936/whats-new-with-popos-2004-lts`}</a></li>
      <li parentName="ul">{`Fedora 32: `}<a parentName="li" {...{
          "href": "https://fedoramagazine.org/whats-new-fedora-32-workstation/"
        }}>{`https://fedoramagazine.org/whats-new-fedora-32-workstation/`}</a></li>
      <li parentName="ul">{`SteamVR avslutar macOS support: `}<a parentName="li" {...{
          "href": "https://store.steampowered.com/newshub/app/250820/view/2216278054495230717"
        }}>{`https://store.steampowered.com/newshub/app/250820/view/2216278054495230717`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Xiaomi sparar browserdata: `}<a parentName="li" {...{
          "href": "https://fossbytes.com/xiaomi-devices-found-tracking-and-recording-browsing-data-of-millions/"
        }}>{`https://fossbytes.com/xiaomi-devices-found-tracking-and-recording-browsing-data-of-millions/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Awesome-selfhosted: `}<a parentName="li" {...{
          "href": "https://github.com/awesome-selfhosted/awesome-selfhosted"
        }}>{`https://github.com/awesome-selfhosted/awesome-selfhosted`}</a></li>
      <li parentName="ul">{`Fri mjukvara i Nederländerna: `}<a parentName="li" {...{
          "href": "https://fsfe.org/news/2020/news-20200424-01.en.html"
        }}>{`https://fsfe.org/news/2020/news-20200424-01.en.html`}</a></li>
      <li parentName="ul">{`Distro-hopping: `}<a parentName="li" {...{
          "href": "https://medium.com/@manujarvinen/setting-up-a-multi-boot-of-5-linux-distributions-ca1fcf8d502"
        }}>{`https://medium.com/@manujarvinen/setting-up-a-multi-boot-of-5-linux-distributions-ca1fcf8d502`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Seb har problem`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Isak undrar: Vad har VSCode som inte Vim har? Utmaningen är väl att enbart använda vim och inte något plugin till VSCode?`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      